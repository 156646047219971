<template>
  <!--  <a-skeleton v-if="detailLoading" />-->
  <div>
    <!-- <a-steps v-model:current="current" type="navigation" size="small" :items="Stage"></a-steps> -->
    <div v-if="listData.institutionsCustomer != null">
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户姓名">
            <span class="detail-text">{{ listData.institutionsCustomer.name }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客资归属人">
            <span class="detail-text">{{ listData.attributablePerson.username }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户来源">
            <span class="detail-text">{{ listData.institutionsCustomer.customerSource }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="咨询项目">
            <span class="detail-text">{{ listData.institutionsCustomer.consultingProject }}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户阶段">
            <span class="detail-text">{{ getStage(listData.institutionsCustomer.customerPhase) }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="创建时间">
            <span>{{ getDate(listData.institutionsCustomer.createdDate) }}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户姓名">
            <span> - </span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客资归属人">
            <span> - </span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="来源渠道">
            <span> - </span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="咨询项目">
            <span> - </span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户阶段">
            <span> - </span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="创建时间">
            <span> - </span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <a-tabs v-model:activeKey="menuKey" type="card" style="margin-top: 20px;">
      <a-tab-pane key="1" tab="跟进记录">
        <a-tabs v-if="institutionsFollowUpGroup != null" v-model:activeKey="followKey" @change="followChange">
          <a-tab-pane v-for="(item, index) in institutionsFollowUpGroup" :key="item.id"
            :tab="item.name + '(' + item.number + ')'">
          </a-tab-pane>
          <table-page :config="config" :key="followKey" />
        </a-tabs>
        <a-empty v-else style="margin-top: 20px;" />
      </a-tab-pane>

      <a-tab-pane key="2" tab="基本信息">
        <a-tabs v-if="basicInformationGroup != null" v-model:activeKey="BasicKey" @change="infoChange">
          <a-tab-pane v-for="(item, index) in basicInformationGroup" :key="item.id" :tab="item.name"></a-tab-pane>
          <div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-row>
                <a-col :span="12">
                  <a-form-item label="微信号">
                    <span class="detail-text">{{ info.wechat || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="手机号">
                    <span class="detail-text">{{ info.phoneNumber || '-' }}</span>
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item label="意向等级">
                    <span class="detail-text">{{ getIntentionLevel(info.intentionLevel) || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="意向国家">
                    <span class="detail-text">{{ info.intendedCountries || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="客户星级">
                    <span class="detail-text">{{getStar(info.star)}}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="预算(人民币)">
                    <span v-if="info.budget" class="detail-text">{{ info.budget }}万</span>
                    <span v-else class="detail-text">-</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="性别">
                    <span v-if="info.gender == 'MALE'">男</span>
                    <span v-else-if="info.gender == 'FEMALE'">女</span>
                    <span v-else>-</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="常驻城市">
                    <span class="detail-text">{{ info.residentCity || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="最高学历">
                    <span>{{ info.highestDegree || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="就读院校">
                    <span class="detail-text">{{ info.college || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="就读专业">
                    <span>{{ info.studyProfessional || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="GPA">
                    <span class="detail-text">{{ info.gpa || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="语言成绩类型">
                    <span>{{ info.languageGradeType || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="语言成绩">
                    <span class="detail-text">{{ info.languageAchievement || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="毕业时间">
                    <span>{{ getDate(info.graduationTime) }}</span>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-item label="其他信息">
                    <span class="detail-text">{{ info.remarks || '-' }}</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                </a-col>
              </a-row>
              <div v-if="BasicKey != -1">
                <a-row>
                  <a-col :span="24">
                    <div style="font-size: 18px;">机构客户关系</div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="创建人">
                      <span class="detail-text">{{ creator || '留服搜索' }}</span>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="归属人">
                      <span class="detail-text">{{ attributionName || '-' }}</span>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="跟进人">
                      <span class="detail-text">{{ followUpWithPeople || '-' }}</span>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="客户状态">
                      <span class="detail-text">{{ getStatus(info.customerStatus) }}</span>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </a-form>
          </div>
        </a-tabs>
        <a-empty v-else style="margin-top: 20px;" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="分配记录">
        <table-page :config="configAllocation" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="签单">
        <a-tabs v-if="orderGrouping != null" v-model:activeKey="orderKey">
          <a-tab-pane key="1" :tab="'进行的订单(' + orderGrouping.proceed + ')'">
            <table-page :config="institutionsOrder" />
          </a-tab-pane>
          <a-tab-pane key="2" :tab="'取消的订单(' + orderGrouping.cancel + ')'">
            <table-page :config="cancelorder" />
          </a-tab-pane>
        </a-tabs>
        <a-empty v-else style="margin-top: 20px;" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { customerStage, intentionLevel, customerStatus, starRating } from '@/utils/public';
import dayjs from "dayjs";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      detailLoading: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      listData: {
        institutionsCustomer: null,
      },
      institutionsFollowUpGroup: null,
      basicInformationGroup: null,
      orderGrouping: null,
      info: {},
      current: 0,
      Stage: customerStage,
      intentionLevel: intentionLevel,
      menuKey: "1",
      followKey: "",
      BasicKey: "",
      infoKey: "1",
      orderKey: "1",
      config: {},
      configAllocation: {},
      institutionsCustomerRelationship: {},
      status: customerStatus,
      creator: '',
      attributionName: '',
      followUpWithPeople: '',

      //进行中的订单
      institutionsOrder: {},

      //取消的订单
      cancelorder: {},
      stars: starRating
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  methods: {
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/agency/institutionsCustomerHighSeas/details", { id: id })
        this.listData = res;

        this.configAllocation = {
          dataUrl: `/agency/institutionsCustomerHighSeas/allocationRecords?id=${this.selectedRows[0].id}`,
          filter: {
            controls: []
          },

          /* 表格操作 */
          actions: [
          ],

          columns: [
            {
              key: 'createdDate',
              title: '分配时间',
              type: 'date',
              width: 120
            },
            {
              key: 'operator.username',
              title: '操作人',
              width: 120
            },
            {
              key: 'institutions.name',
              title: '机构名',
              width: 120
            }
          ],
          /* 表格排序 */
          sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
          },

          /* 表格行操作 */
          rowActions: []

        };
        this.institutionsOrder = {
          dataUrl: `/agency/institutionsCustomerHighSeas/ordersInProgress?id=${this.selectedRows[0].id}`,
          filter: {
            controls: []
          },

          /* 表格操作 */
          actions: [
          ],

          columns: [
            {
              key: 'orderNumber',
              title: '订单号',
              width: 120
            },
            {
              key: 'institutions.name',
              title: '签单机构',
              width: 120
            },
            {
              key: 'rebatesRules.amountToBeRefunded',
              width: '150px',
              title: '应返金额',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'rebatesRules.returnedAmount',
              width: '150px',
              title: '已返金额',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'signingTime',
              title: '签单时间',
              type: 'datetime',
              width: 120
            },
            {
              key: 'serviceItem.content',
              title: '服务项目',
              width: 120
            },
            {
              key: 'orderAmount',
              title: '订单金额',
              width: '130px',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'orderStatus',
              title: '订单情况',
              width: '100px',
              ellipsis: true
            },
          ],
          /* 表格排序 */
          sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
          },

          /* 表格行操作 */
          rowActions: []

        };
        this.cancelorder = {
          dataUrl: `/agency/institutionsCustomerHighSeas/cancelledOrders?id=${this.selectedRows[0].id}`,
          filter: {
            controls: []
          },

          /* 表格操作 */
          actions: [
          ],

          columns: [
            {
              key: 'orderNumber',
              title: '订单号',
              width: 120
            },
            {
              key: 'institutions.name',
              title: '签单机构',
              width: 120
            },
            {
              key: 'signingTime',
              width: '130px',
              title: '签单时间',
              type: 'datetime'
            },
            {
              key: 'cancelTime',
              width: '130px',
              title: '取消时间',
              type: 'datetime'
            },
            {
              key: 'serviceItem.content',
              width: '130px',
              title: '服务项目',
            },
            {
              key: 'orderAmount',
              width: '130px',
              title: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'amountToBeRefunded',
              width: '130px',
              title: '应退金额',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'refundedAmount',
              width: '130px',
              title: '已退金额',
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },
            {
              key: 'reasonForCancellation',
              title: '取消原因',
              width: 200
            }
          ],
          /* 表格排序 */
          sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
          },

          /* 表格行操作 */
          rowActions: []
        };
        this.current = this.Stage.findIndex(item => item.value === this.listData.customerPhase);
        this.Stage = this.Stage.map((item, index) => {
          if (index < this.current + 1) {
            return {
              ...item,
              status: 'finish',
              disabled: true,
            };
          }
          return { ...item, disabled: true };
        });
        if (res.followUpRecordsCount > 0 && res.institutionsFollowUpGrouping.length == 0) {
          this.institutionsFollowUpGroup = [{ id: -1, number: res.followUpRecordsCount, name: "留服搜索" }];
          this.followKey = -1;
          this.config = {
            dataUrl: `/agency/institutionsCustomerHighSeas/internalFollowUpRecords?id=${this.selectedRows[0].id}`,
            filter: {
              controls: []
            },
            /* 表格操作 */
            actions: [
            ],
            columns: [
              {
                key: 'institutionsCustomer.name',
                title: '客户姓名',
                width: 120
              },
              {
                key: 'followUpTime',
                title: '跟进时间',
                type: 'datetime',
                width: 120
              },
              {
                key: 'followUpPeople.name',
                title: '跟进人',
                width: 120
              },
              {
                key: 'followUpContent',
                title: '跟进内容',
                width: '200px',
                ellipsis: true
              }
            ],
            /* 表格排序 */
            sorter: {
              /* 可排序列 */
              sortableColumns: ['id'],
              /* 默认排序 */
              defaultSort: '-id'
            },
            /* 表格行操作 */
            rowActions: []
          };
        } else if (res.followUpRecordsCount > 0 && res.institutionsFollowUpGrouping.length > 0) {
          this.followKey = -1;
          this.institutionsFollowUpGroup = [{ id: -1, number: res.followUpRecordsCount, name: "留服搜索" }];
          this.institutionsFollowUpGroup = this.institutionsFollowUpGroup.concat(res.institutionsFollowUpGrouping);
          this.config = {
            dataUrl: `/agency/institutionsCustomerHighSeas/internalFollowUpRecords?id=${this.selectedRows[0].id}`,
            filter: {
              controls: []
            },
            /* 表格操作 */
            actions: [
            ],
            columns: [
              {
                key: 'institutionsCustomer.name',
                title: '客户姓名',
                width: 120
              },
              {
                key: 'followUpTime',
                title: '跟进时间',
                type: 'datetime',
                width: 120
              },
              {
                key: 'followUpPeople.name',
                title: '跟进人',
                width: 120
              },
              {
                key: 'followUpContent',
                title: '跟进内容',
                width: '200px',
                ellipsis: true
              }
            ],
            /* 表格排序 */
            sorter: {
              /* 可排序列 */
              sortableColumns: ['id'],
              /* 默认排序 */
              defaultSort: '-id'
            },
            /* 表格行操作 */
            rowActions: []
          };
        } else if (res.followUpRecordsCount == 0 && res.institutionsFollowUpGrouping.length > 0) {
          this.followKey = res.institutionsFollowUpGrouping[0].id;
          this.institutionsFollowUpGroup = res.institutionsFollowUpGrouping;
          this.config = {
            dataUrl: `/agency/institutionsCustomerHighSeas/followUpRecords?id=${this.selectedRows[0].id}&institutionsIdIs=${res.institutionsFollowUpGrouping[0].id}`,
            filter: {
              controls: []
            },
            /* 表格操作 */
            actions: [
            ],
            columns: [
              {
                key: 'institutionsCustomerRelationship.name',
                title: '客户姓名',
                width: 120
              },
              {
                key: 'followUpTime',
                title: '跟进时间',
                type: 'datetime',
                width: 120
              },
              {
                key: 'followUpPeople.name',
                title: '跟进人',
                width: 120
              },
              {
                key: 'followUpContent',
                title: '跟进内容',
                width: '200px',
                ellipsis: true
              }
            ],
            /* 表格排序 */
            sorter: {
              /* 可排序列 */
              sortableColumns: ['id'],
              /* 默认排序 */
              defaultSort: '-id'
            },
            /* 表格行操作 */
            rowActions: []
          };
        }
        // if (res.institutionsFollowUpGrouping.length > 0) {
        //   let total = res.institutionsFollowUpGrouping.reduce((prev, item) => {
        //     return prev + item.number;
        //   }, 0)
        //   this.institutionsFollowUpGroup = [{ id: "", number: total, name: "全部" }];
        //   this.institutionsFollowUpGroup = this.institutionsFollowUpGroup.concat(res.institutionsFollowUpGrouping);
        // };
        this.institutionsCustomerRelationship = res.institutionsCustomer;
        if (res.institutionsCustomer != null && res.basicInformationGroup.length > 0) {
          this.basicInformationGroup = [{ id: -1, number: null, name: "留服搜索" }];
          this.basicInformationGroup = this.basicInformationGroup.concat(res.basicInformationGroup)
          this.info = res.institutionsCustomer;
          this.BasicKey = -1;
        } else if (res.institutionsCustomer != null && res.basicInformationGroup.length == 0) {
          this.basicInformationGroup = [{ id: -1, number: null, name: "留服搜索" }];
          this.info = res.institutionsCustomer;
          this.BasicKey = -1;
        } else if (res.institutionsCustomer == null && res.basicInformationGroup.length > 0) {
          this.basicInformationGroup = res.basicInformationGroup;
          this.BasicKey = this.basicInformationGroup[0].id;
          this.getInfo(this.basicInformationGroup[0].id)
        }

        // if (res.basicInformationGroup.length > 0) {
        //   this.basicInformationGroup = res.basicInformationGroup;
        //   this.BasicKey = this.basicInformationGroup[0].id;
        //   this.getInfo(this.basicInformationGroup[0].id)
        // }
        this.orderGrouping = res.orderGrouping;
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

    async getInfo(id) {
      try {
        let res = await get("/agency/institutionsCustomerHighSeas/basicInformation", {
          id: this.selectedRows[0].id,
          institutionsIdIs: id
        })
        this.info = res.InstitutionsCustomerRelationship;
        this.creator = res.creator;
        this.attributionName = res.attribution ? res.attribution.name : '';
        this.followUpWithPeople = res.followUpWithPeople ? res.followUpWithPeople.name : '';
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    followChange(e) {
      if (e == -1) {
        this.config.dataUrl = `/agency/institutionsCustomerHighSeas/internalFollowUpRecords?id=${this.selectedRows[0].id}`;
        this.config.columns = [
          {
            key: 'institutionsCustomer.name',
            title: '客户姓名',
            width: 120
          },
          {
            key: 'followUpTime',
            title: '跟进时间',
            type: 'datetime',
            width: 120
          },
          {
            key: 'followUpPeople.name',
            title: '跟进人',
            width: 120
          },
          {
            key: 'followUpContent',
            title: '跟进内容',
            width: '200px',
            ellipsis: true
          }
        ]
      } else {
        this.config.dataUrl = `/agency/institutionsCustomerHighSeas/followUpRecords?id=${this.selectedRows[0].id}&institutionsIdIs=${e}`
        this.config.columns = [
          {
            key: 'institutionsCustomerRelationship.name',
            title: '客户姓名',
            width: 120
          },
          {
            key: 'followUpTime',
            title: '跟进时间',
            type: 'datetime',
            width: 120
          },
          {
            key: 'followUpPeople.name',
            title: '跟进人',
            width: 120
          },
          {
            key: 'followUpContent',
            title: '跟进内容',
            width: '200px',
            ellipsis: true
          }
        ]
      }
    },

    infoChange(e) {
      if (e == -1) {
        this.info = this.institutionsCustomerRelationship;
      } else {
        this.getInfo(e);
      }
    },

    getDate(date) {
      if (date != null) {
        return dayjs(date).format('YYYY-MM-DD')
      } else {
        return '-'
      }
    },

    getStage(val) {
      if (val) {
        let dts = this.Stage.filter(item => {
          return item.id === val
        })
        return dts[0].text || '-'
      } else {
        return '-'
      }
    },

    getIntentionLevel(val) {
      if (val) {
        let dts = this.intentionLevel.filter(item => {
          return item.value === val
        })
        return dts[0].text || '-'
      } else {
        return '-'
      }
    },

    getStatus(val) {
      if (val) {
        let dts = this.status.filter(item => {
          return item.value === val
        })
        return dts[0].text || '-'
      } else {
        return '-'
      }
    },

    getStar(val) {
      if (val) {
        let dts = this.stars.filter(item => {
          return item.value === val
        })
        return dts[0].text || '-'
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
